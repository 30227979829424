<template>
  <div style="margin-bottom: 200px">
    <div>
      <div
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mb-4"
      >
        <div class="col-4 col-md-4">
          <base-input
            v-model="query"
            type="search"
            prepend-icon="fas fa-search"
            :placeholder="$t('COMMON.SEARCH')"
            clearable
          />
        </div>

        <div class="col-4 col-md-4">
          <base-input>
            <category-group-selector
              :allowNone="true"
              :showAll="false"
              :isOnlyModels="false"
              :placeholder="$t('COMMON.CATEGORIES')"
              @categoryChanged="
                (categoryId) => {
                  selectedCategory = categoryId;
                }
              "
            />
          </base-input>
        </div>

        <div
          class="col-4 col-md-4"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SUPPLIERS)"
        >
          <base-input>
            <supplier-selector
              :filterOrganization="stockMovement.organization.id"
              :filterWarehouseBuyingPriceNotNull="getWarehouse()"
              @supplierChanged="(supplierId) => (selectedSupplier = supplierId)"
            />
          </base-input>
        </div>
      </div>

      <div ref="triggerElement" style="width: 100%; height: 1px"></div>

      <el-table
        ref="stickyDiv"
        class="table-responsive align-items-center table-flush stock-movement-product-list"
        :class="{ sticky: isSticky }"
        header-row-class-name="thead-light"
        :data="filteredStockMovementItemsModel"
        type="expand"
      >
        <div slot="empty" v-if="loading || !stockMovement.id">
          <img src="/img/loading.gif" style="height: 100px; width: 100px" />
        </div>
        <el-table-column
          :label="$t('COMMON.PRODUCT')"
          prop="product.name"
          min-width="400px"
        >
          <template v-slot="{ row }">
            <base-input v-if="row.id < 0">
              <product-selector
                :filterOrganization="stockMovement.organization.id"
                :product="stockMovementItemsModel[row.id].storable.id"
                :filterWarehouse="getWarehouse()"
                :allowNone="false"
                :showAll="false"
                :disabled="row.id > 0"
                :filterIdsNotIn="existingProducts"
                @productChanged="
                  (productId, product) => {
                    stockMovementItemsModel[row.id].storable = product;
                    updateStockMovementItemDebounced(row.id);
                  }
                "
              />
            </base-input>
            <div v-if="row.storable && row.id > 0">
              <object-link :object="row.storable" /><br />
              <span class="text-muted">
                {{ row.storable.categoriesNames }}<br />
                {{ row.storable.sku }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('STOCK_MOVEMENTS.ORDER_UNIT_OF_MEASURE')"
          prop="quantity"
          width="140px"
        >
          <template v-slot="{ row }">
            <object-link
              v-if="stockMovementItemsModel[row.id]?.storable.orderUnitOfMeasureUnit"
              :object="stockMovementItemsModel[row.id].storable.orderUnitOfMeasureUnit"
            />
          </template>
        </el-table-column>
        <el-table-column
          v-if="!!stockMovement || stockMovement.movement_type == TYPE_CORRECT"
          :label="$t('COMMON.WAREHOUSE_QUANTITY')"
          prop="quantity"
          width="120px"
        >
          <template v-slot="{ row }">
            <span>
              {{ stockMovementItemsModel[row.id].warehouse_quantity }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          :label="`${
            stockMovement.movement_type == TYPE_CORRECT
              ? $t('COMMON.AJUSTED_QUANTITY')
              : $t('COMMON.QUANTITY')
          }`"
          prop="quantity"
          width="120px"
        >
          <template v-slot="{ row }">
            <base-input
              v-if="
                stockMovement.status == STATUS_DRAFT && stockMovementItemsModel[row.id]
              "
              class="my-5"
              placeholder="0"
              type="number"
              step="0.01"
              v-model="stockMovementItemsModel[row.id].quantity"
              input-classes="form-control-alternative"
              @change="updateStockMovementItemDebounced(row.id)"
            />
            <span v-if="stockMovement.status != STATUS_DRAFT">
              {{ stockMovementItemsModel[row.id].quantity }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('STOCK_MOVEMENTS.STORAGE_UNIT')"
          prop="quantity"
          width="120px"
        >
          <template v-slot="{ row }">
            <i
              v-if="
                stockMovement.status == STATUS_DRAFT &&
                !stockMovementItemsModel[row.id]?.unitOfMeasureEdit
              "
              class="fa fa-pencil-alt mr-1"
              style="cursor: pointer"
              @click="
                () => {
                  stockMovementItemsModel[row.id].unitOfMeasureEdit = true;
                }
              "
            />
            <unit-of-measure-unit-selector
              v-if="
                stockMovement.status == STATUS_DRAFT &&
                stockMovementItemsModel[row.id]?.unitOfMeasureId &&
                stockMovementItemsModel[row.id]?.unitOfMeasureEdit
              "
              :filterOrganization="stockMovement.organization.id"
              :filterUnitOfMeasure="stockMovementItemsModel[row.id].unitOfMeasureId"
              :unit="stockMovementItemsModel[row.id]?.unitOfMeasureUnit?.id"
              :allowNone="false"
              :showAll="false"
              @unitChanged="
                (unit) => {
                  stockMovementItemsModel[row.id].unitOfMeasureUnit = unit;
                  updateStockMovementItemDebounced(row.id);
                }
              "
            />
            <object-link
              v-if="
                stockMovementItemsModel[row.id]?.unitOfMeasureId &&
                !stockMovementItemsModel[row.id]?.unitOfMeasureEdit
              "
              :object="stockMovementItemsModel[row.id].unitOfMeasureUnit"
            />
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('COMMON.BUYING_PRICE')"
          prop="buying_price"
          width="80px"
        >
          <template v-slot="{ row }">
            <span v-if="stockMovementItemsModel[row.id]?.storable.buying_price">
              {{ $formatCurrency(stockMovementItemsModel[row.id].buying_unit_price) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('COMMON.TOTAL_VALUE')"
          prop="buying_price"
          width="80px"
        >
          <template v-slot="{ row }">
            <span v-if="stockMovementItemsModel[row.id]?.storable.buying_price">
              {{ $formatCurrency(stockMovementItemsModel[row.id].buying_total_value) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" width="50px">
          <div slot-scope="{ row }" class="table-actions">
            <el-tooltip
              content="Loading"
              placement="top"
              v-if="
                stockMovementItemsModel[row.id] && stockMovementItemsModel[row.id].loading
              "
            >
              <a
                type="text"
                class="table-action table-action-delete"
                data-toggle="tooltip"
              >
                <i class="fas fa-spinner fa-spin"></i>
              </a>
            </el-tooltip>

            <el-tooltip
              content="Delete"
              placement="top"
              v-if="
                $currentUserCan($permissions.PERM_EDIT_PRODUCTS) &&
                stockMovement.status == STATUS_DRAFT
              "
            >
              <a
                type="text"
                @click="deleteStockMovementItem(row)"
                class="table-action table-action-delete"
                data-toggle="tooltip"
                style="cursor: pointer"
              >
                <i class="fas fa-trash"></i>
              </a>
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>

      <div class="row px-3 mt-3" v-if="stockMovement.status == STATUS_DRAFT">
        <base-button
          type="info"
          class="btn-sm mr-1 col-12"
          style="width: 100%"
          @click="
            () => {
              addLine();
            }
          "
          v-if="stockMovement.movement_type != TYPE_CORRECT"
        >
          <i class="fas fa-plus"></i>
          {{ $t("PRODUCTS.ADD_PRODUCT") }}
        </base-button>
      </div>
    </div>

    <div class="stock-movement-summary">
      <table>
        <tbody>
          <tr>
            <td>{{ $t("COMMON.TOTAL_VALUE") }}</td>
            <td colspan="2" class="total">
              {{ $formatCurrency(getTotalValue()) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import {
  TYPE_CORRECT,
  TYPE_ADD,
  TYPE_REMOVE,
  TYPE_MOVE,
  STATUS_DRAFT,
} from "@/constants/stockMovements";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ProductSelector from "@/components/ProductSelector.vue";
import UnitOfMeasureUnitSelector from "@/components/UnitOfMeasureUnitSelector.vue";
import SupplierSelector from "@/components/SupplierSelector.vue";
import CategoryGroupSelector from "@/components/CategoryGroupSelector.vue";

export default {
  name: "product-list-table",

  components: {
    ProductSelector,
    UnitOfMeasureUnitSelector,
    SupplierSelector,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    CategoryGroupSelector,
  },

  mixins: [requestErrorMixin],

  props: {
    stockMovement: {
      type: Object,
      default: null,
      description: "StockMovement",
    },
  },

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-created_at",
      filteredStockMovementItemsModel: [],
      loading: true,
      selectedProduct: null,
      selectedSupplier: null,
      selectedCategory: null,
      stockMovementItemsModel: {},
      deletedStockMovementItems: {},
      TYPE_CORRECT: TYPE_CORRECT,
      STATUS_DRAFT: STATUS_DRAFT,
      existingProducts: [],
      isSticky: false,
    };
  },

  computed: {},

  watch: {
    stockMovement: {
      handler: "refreshStockMovementItemsDebounced",
      immediate: true,
    },
    selectedProduct: {
      handler: "filterStockMovementItemsDebounced",
      immediate: true,
    },
    selectedSupplier: {
      handler: "filterStockMovementItemsDebounced",
      immediate: true,
    },
    selectedCategory: {
      handler: "filterStockMovementItemsDebounced",
      immediate: true,
    },
    query: {
      handler: "filterStockMovementItemsDebounced",
      immediate: true,
    },
  },

  mounted() {
    this.refreshStockMovementItems();
    window.addEventListener("scroll", this.handleScroll);
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    updateStockMovementItemDebounced: _.debounce(function (id) {
      this.updateStockMovementItem(id);
    }, 300),
    refreshStockMovementItemsDebounced: _.debounce(function (id) {
      this.refreshStockMovementItems();
    }, 300),
    filterStockMovementItemsDebounced: _.debounce(function (id) {
      this.filterStockMovementItems();
    }, 300),

    getWarehouse() {
      if (this.stockMovement.movement_type == TYPE_CORRECT) {
        return this.stockMovement.sourceWarehouse.id;
      } else if (this.stockMovement.movement_type == TYPE_ADD) {
        if (this.stockMovement.destinationWarehouse.allow_unregistered_products) {
          return null;
        }
        return this.stockMovement.destinationWarehouse.id;
      } else if (this.stockMovement.movement_type == TYPE_MOVE) {
        return this.stockMovement.sourceWarehouse.id;
      } else if (this.stockMovement.movement_type == TYPE_REMOVE) {
        return this.stockMovement.sourceWarehouse.id;
      }
    },

    async filterStockMovementItems() {
      this.loading = true;
      this.filteredStockMovementItemsModel = Object.values(
        this.stockMovementItemsModel
      ).filter((item) => {
        this.loading = true;
        if (item.id < 0) {
          return true;
        }
        // if (this.stockMovement.movement_type == TYPE_CORRECT) {
        //   if(item.warehouse_quantity == 0) {
        //     return false;
        //   }
        // }
        if (this.selectedProduct) {
          if (this.selectedProduct != item.storable.id) {
            return false;
          }
        }
        if (this.selectedSupplier) {
          if (!item.storable.suppliers.includes(this.selectedSupplier)) {
            return false;
          }
        }
        if (this.selectedCategory) {
          if (!item.storable.categories.includes(this.selectedCategory)) {
            return false;
          }
        }
        if (this.query) {
          const formattedSku = item.storable.sku.toLowerCase();
          const formattedName = item.storable.name.toLowerCase();
          const formattedQuery = this.query.toLowerCase();

          if (
            !formattedName.includes(formattedQuery) &&
            !formattedSku.includes(formattedQuery)
          ) {
            return false;
          }
        }
        return true;
      });
      this.loading = false;
    },

    async deleteStockMovementItem(row) {
      if (row.id > 0) {
        const confirmation = await swal.fire({
          title: this.$t("STOCK_MOVEMENTS.DELETE_THIS_STOCK_MOVEMENT_PRODUCT"),
          type: "question",
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: this.$t("COMMON.YES"),
          cancelButtonText: this.$t("COMMON.NO"),
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        });
        if (confirmation.value !== true) {
          return;
        }
        try {
          this.stockMovementItemsModel[row.id].loading = true;
          await this.$store.dispatch("stockMovementItems/destroy", row.id);
          delete this.stockMovementItemsModel[row.id];
          this.existingProducts = this.existingProducts.filter(
            (item) => item != row.storable.id
          );
          await this.filterStockMovementItemsDebounced();
        } catch (error) {
          await this.showRequestError(error);
        }
      }

      this.$emit("stockMovementItemsUpdated");
    },

    async updateStockMovementItem(id) {
      this.stockMovementItemsModel[id].loading = true;
      const stockMovementItemData = this.stockMovementItemsModel[id];
      try {
        if (stockMovementItemData.id > 0) {
          await this.$store.dispatch("stockMovementItems/update", stockMovementItemData);
          const stockMovementItem = this.$store.getters[
            "stockMovementItems/stockMovementItem"
          ];
          this.refreshStockMovementModelItem(stockMovementItem);
          await this.filterStockMovementItemsDebounced();
          this.$emit("stockMovementItemsUpdated");
        } else {
          if (
            stockMovementItemData.storable.id &&
            stockMovementItemData.stockMovement.id
          ) {
            delete stockMovementItemData.id;
            await this.$store.dispatch("stockMovementItems/add", stockMovementItemData);
          }
          const stockMovementItem = await this.$store.getters[
            "stockMovementItems/stockMovementItem"
          ];
          delete this.stockMovementItemsModel[id];
          this.refreshStockMovementModelItem(stockMovementItem);
          this.existingProducts.push(stockMovementItem.storable.id);
          await this.filterStockMovementItemsDebounced();
          this.$emit("stockMovementItemsAdded");
        }
      } catch (error) {
        await this.showRequestError(error);
      }
    },

    addLine() {
      let newLinesIds = Object.values(this.stockMovementItemsModel)
        .filter((item) => item.id < 0)
        .map((item) => item.id);
      newLinesIds.push(-1);
      const newLineId = Math.min(...newLinesIds) - 1;

      const newStockMovementItem = {
        type: "stock-movement-items",
        id: newLineId,
        quantity: 0,
        relationshipNames: ["storable", "stockMovement"],
        storable: {
          type: "products",
          id: null,
        },
        stockMovement: {
          type: "stock-movements",
          id: this.stockMovement.id,
        },
        unitOfMeasureUnit: {
          type: "unit-of-measure-units",
          id: null,
        },
        loading: false,
      };
      this.stockMovementItemsModel[newLineId] = _.cloneDeep(newStockMovementItem);
      this.filterStockMovementItems();
    },

    refreshStockMovementItems() {
      if (!this.stockMovement.id) {
        return;
      }

      this.loading = true;
      this.filteredStockMovementItemsModel = [];
      for (const item of this.stockMovement.items) {
        this.refreshStockMovementModelItem(item);
      }
      this.existingProducts = Object.values(this.stockMovementItemsModel).map(
        (item) => item.storable.id
      );
      this.filterStockMovementItemsDebounced();
    },

    refreshStockMovementModelItem(item) {
      this.stockMovementItemsModel[item.id] = {
        type: "stock-movement-items",
        id: item.id,
        quantity: item.quantity,
        warehouse_quantity: item.warehouse_quantity,
        relationshipNames: ["storable", "stockMovement", "unitOfMeasureUnit"],
        storable: {
          type: item.storable.type,
          id: item.storable.id,
          name: item.storable.name,
          buying_price: item.storable.buying_price,
          selling_price: item.storable.selling_price,
          sku: item.storable.sku,
          suppliers: item.storable.suppliers.map((sup) => sup.id),
          categories: item.storable.categories?.map((cat) => cat.id),
          categoriesNames: item.storable.categories?.map((cat) => cat.name).join(", "),
          orderUnitOfMeasureUnit: item.storable.orderUnitOfMeasureUnit,
        },
        stockMovement: {
          type: "stock-movements",
          id: item.stockMovement.id,
        },
        unitOfMeasureUnit: {
          type: "unit-of-measure-units",
          id: item.unitOfMeasureUnit?.id,
          name: item.unitOfMeasureUnit?.name,
        },
        unitOfMeasureId: item.storable.unitOfMeasure?.id,
        unitOfMeasureEdit: false,
        buying_unit_price: item.buying_unit_price,
        buying_total_value: item.buying_total_value,
        loading: false,
      };
    },

    getTotalValue() {
      return Object.values(this.stockMovementItemsModel).reduce(
        (accumulator, currentValue) => {
          return accumulator + currentValue.buying_total_value;
        },
        0
      );
    },

    handleScroll() {
      const getElementPositionFromTopOfPage = (element) => {
        const rect = element.getBoundingClientRect();
        const positionFromTopOfPage = rect.top + window.scrollY;
        return positionFromTopOfPage;
      };
      const triggerElement = this.$refs.triggerElement;
      const triggerRect = triggerElement.getBoundingClientRect();
      const stickyTop = getElementPositionFromTopOfPage(this.$refs.stickyDiv.$el);

      this.$refs.stickyDiv.$el.querySelector(".el-table__header-wrapper").style.top = `${
        window.scrollY - stickyTop
      }px`;

      if (window.scrollY >= triggerRect.bottom + 100) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    },
  },
};
</script>

<style>
.stock-movement-product-list .el-table__row .form-group {
  margin-bottom: 0;
}
.stock-movement-product-list .form-group.taxes-selector .remove-taxes-button {
  display: flex;
  align-items: center;
}
.stock-movement-summary {
  position: fixed;
  bottom: 0;
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 999;
  height: auto;
  min-height: 200px;
  width: auto;
  min-width: 600px;
  padding-bottom: 20px;
}

.stock-movement-summary > table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 10px 10px;
}

.stock-movement-summary > div {
  margin-bottom: 15px;
}

.stock-movement-summary span {
  margin-right: 10px;
  font-weight: bold;
}

.total {
  background-color: #4caf50;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  margin-left: auto;
}
.sticky .el-table__header-wrapper {
  position: absolute;
  top: 50px;
  z-index: 99;
}
</style>
